<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Content</h2>
      <v-btn v-if="canCreateArchive" text @click="openArchive">
        <v-icon left>mdi-archive-arrow-down-outline</v-icon>
        Download
      </v-btn>
    </div>

    <!-- Card -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center">
        <profile-picker v-model="profileId" />
        <div>
        <filters-picker v-model="filters" />
        <period-picker v-model="period" @computed="computedPeriod = $event" show-recent />
        </div>
      </v-card-header>
      <v-card-text>
        <!-- Thumbnails -->
        <v-row v-if="filteredItems.length">
          <v-col v-for="(item, index) in filteredItems" :key="item.id" cols="6" sm="4" md="3">
            <div class="thumbnail" @click="showPreview(index)">
              <deliverable-thumbnail :deliverable="item" />
              <div class="thumbnail-info d-flex justify-space-between align-center">
                <span class="text-caption">{{$formatDateTime(item.create_time)}}</span>
                <span @click.stop>
                  <v-btn dark icon :to="{name: 'content-detail', params: {id: item.id}}">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        <async-state v-if="profileId && computedPeriod" :promise="promise" :has-data="items.length > 0">
          <div v-if="nextPageToken" class="text-center my-4">
            <v-btn text @click="loadMore">
              Load more
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </async-state>
        <p v-else-if="!profileId" class="text-center grey--text my-4">
          Select a profile to view content
        </p>
      </v-card-text>
    </v-card>

    <!-- Dialogs -->
    <deliverable-preview-dialog
      ref="preview"
      nav
      :previousDisabled="previewItemIndex === 0"
      :nextDisabled="previewItemIndex === filteredItems.length - 1"
      @previous="showPreview(previewItemIndex - 1)"
      @next="showPreview(previewItemIndex + 1)"
    />

    <archive-dialog ref="archive" />

  </v-container>
</template>

<script>
import ProfilePicker from "../components/ProfilePicker";
import PeriodPicker from "../components/PeriodPicker.vue";
import FiltersPicker from "../components/FiltersPicker.vue";
import DeliverableThumbnail from "../components/DeliverableThumbnail.vue";
import dayjs from 'dayjs';
import ArchiveDialog from '../components/ArchiveDialog.vue';
import AsyncState from '../components/AsyncState.vue';
import DeliverablePreviewDialog from '../components/DeliverablePreviewDialog.vue';
import axios from 'axios';
import Filters from "../filters";

export default {
  name: "Content",
  components: { ProfilePicker, PeriodPicker,FiltersPicker, ArchiveDialog, AsyncState, DeliverableThumbnail, DeliverablePreviewDialog },

  data() {
    return {
      period: "recent",
      computedPeriod: null,
      
      items: [],
      promise: null,
      nextPageToken: null,
      cancelToken: null,

      previewItemIndex: null,
      filters: null
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    canCreateArchive() {
      return this.$root.permissions.canPerformType("create_archive", "profile");
    },

    filteredItems() {
      return this.filters ? this.filters.apply(this.items) : this.items;
    },
  },

  methods: {
    loadInit() {
      this.items = [];
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let order_by = null, start_time = null, end_time = null;
        if (this.computedPeriod.preset === 'recent') {
          order_by = 'desc';
        } else {
          order_by = 'asc';
          start_time = dayjs(this.computedPeriod.start).startOf('day').toISOString();
          end_time = dayjs(this.computedPeriod.end).add(1, 'day').startOf('day').toISOString();
        }

        let response = await this.$api.get("/deliverables", {
          params: {
            profile_id: this.profileId,
            page_size: 20,
            page_token: this.nextPageToken,
            order_by,
            start_time,
            end_time
          },
          cancelToken: this.cancelToken.token
        });
        this.items = this.items.concat(response.data.items);
        this.nextPageToken = response.data.next_page_token;
      })();
    },

    showPreview(index) {
      this.previewItemIndex = index;
      this.$refs.preview.open(this.filteredItems[index]);
    },

    openArchive() {
      this.$refs.archive.open(this.profileId, this.period === "recent" ? "today" : this.period, this.filters);
    }
  },

  watch: {
    profileId: {
      async handler(newVal) {
        this.filters = new Filters();
        if (newVal) {
          let profile = await this.$root.getProfile(newVal);
          this.filters = Filters.fromSchema(profile.schemas.deliverable);
          
          if (this.$root.isJDHack(newVal)) {
            for (let filter of this.filters) {
              if (filter.id == "opted_in") {
                filter.value = true;
                filter.enabled = true;
                filter.readonly = true;
              }
            }
          }

          this.loadInit();
        }
      },
      immediate: true
    },

    computedPeriod() {
      if (this.profileId)
        this.loadInit();
    }
  }
};
</script>

<style scoped>
.thumbnail {
  position: relative;
  cursor: pointer;
}

.thumbnail-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 8px 4px 8px;
  color: #fff;
  background: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 1));
  opacity: 0;
  transition: opacity 0.1s;
}

.thumbnail:hover .thumbnail-info {
  opacity: 1;
}
</style>